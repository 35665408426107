import React, { useState, useEffect, useRef } from "react"
import {
  GlassBox,
  Box,
  Text,
  IconButton,
  Spinner,
} from "../../components/commonElements"
import { MoreVert } from "@styled-icons/material-rounded"
import { TrashCan, FolderOpen } from "@styled-icons/fa-solid"
import styled from "styled-components"
import { deleteEmbedLink } from "../../redux/embedLink/actions"
import { useAsyncDispatch } from "../../components/hook/useAsync"
import { useToastContext } from "../contextHook/toast"

const DropdownContainer = styled(GlassBox)`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0.5rem;
  & > div {
    &:nth-child(1) {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
    &:nth-last-child(1) {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
`

const DropdownItem = styled(Box)`
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  height: 3rem;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
    color: ${props => props.theme.colors.text};
  }
`

const MoreButton = ({ embedPage, fullPath, path, removeLink }) => {
  const options = [
    { mode: "Open", icon: FolderOpen, label: "Open" },
    { mode: "Delete", icon: TrashCan, label: "Delete" },
  ]

  const [open, setOpen] = useState(false)
  const { execute: deleteEmbedLinkAsync, loading: deleteEmbedLinkLoading } =
    useAsyncDispatch(deleteEmbedLink)
  const { setToast } = useToastContext()

  const refBox = useRef()

  const handleClickOutside = event => {
    if (!refBox.current || !refBox.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (typeof window === "undefined") return
    window.addEventListener("mousedown", handleClickOutside)
    window.addEventListener("touchstart", handleClickOutside)

    return () => {
      window.removeEventListener("mousedown", handleClickOutside)
      window.removeEventListener("touchstart", handleClickOutside)
    }
  }, [])

  const modeChanged = mode => {
    if (mode === "Open") {
      window.open(fullPath, "_blank")
    } else if (mode === "Delete") {
      deleteEmbedLinkClicked()
    }
  }

  const deleteEmbedLinkClicked = async () => {
    try {
      const data = {
        embedPage: embedPage,
        embedPath: path,
      }

      setOpen(false)
      const result = await deleteEmbedLinkAsync(data)
      if (!result.error) {
        removeLink()
      } else {
        setToast(`Error. ${result.error}`)
      }
    } catch (error) {
      setToast(
        `Error. We can't delete the link. Please try again later. If error persists, please let us know.`
      )
      console.log("error", error)
    }
  }

  return (
    <Box positionBox="relative" mt=".4rem">
      <IconButton
        padding="0 1rem"
        onClick={() => setOpen(true)}
        icon={deleteEmbedLinkLoading ? <Spinner /> : <MoreVert size="16" />}
      />

      {open && (
        <Box
          ref={refBox}
          onMouseLeave={() => setOpen(false)}
          positionBox="absolute"
          rightP="0"
          zIndex="7"
          minWidth="13.5rem"
        >
          <DropdownContainer>
            {options.map(({ mode, icon: Icon, label }) => (
              <DropdownItem key={mode} onClick={() => modeChanged(mode)}>
                <Icon size={14} />
                <Text fontSize="1.4rem">{label}</Text>
              </DropdownItem>
            ))}
          </DropdownContainer>
        </Box>
      )}
    </Box>
  )
}

export default MoreButton
