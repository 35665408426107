import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  GlassBox,
  Box,
  Heading,
  IconButton,
  Tooltip,
  Text,
} from "../commonElements"
import styled from "styled-components"
import { ContentCopy } from "@styled-icons/material-rounded"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { createEmbedLink } from "../../redux/embedLink/actions"

const InstructionBox = styled(GlassBox)`
  padding: 1rem;
  margin-bottom: 2rem;
  min-height: 5rem;
  background-color: #3a3a3a;
  border: none;
  color: white;
`

const ContentHeading = styled(Text)`
  /* font-size: 1.6rem; */
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 1rem;
`

const Content = styled.pre`
  font-size: 1.6rem;
  overflow-x: auto;
  margin-bottom: 0;
  padding: 1rem;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

const CopyButton = ({ copyText, text, setCopyText }) => {
  return (
    <Tooltip content={copyText}>
      <CopyToClipboard
        text={text}
        onCopy={() => {
          setCopyText("Copied")
        }}
      >
        <IconButton
          mr=".5rem"
          onMouseLeave={() => {
            setCopyText("Copy to Clipboard")
          }}
          icon={<ContentCopy size={22} />}
        />
      </CopyToClipboard>
    </Tooltip>
  )
}

const EmbedInstruction = ({ getCodeClicked, setGetCodeClicked }) => {
  const { tool: toolPersist, height: heightPersist } = useSelector(
    state => state.embed
  )

  const [copyText, setCopyText] = useState("Copy to Clipboard")
  const [embedUrl, setEmbedUrl] = useState(null)
  const [iFrameCode, setIFrameCode] = useState(null)
  const [tool, setTool] = useState(toolPersist)
  const [height, setHeight] = useState(heightPersist)

  const [errorMsg, setErrorMsg] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (toolPersist !== tool) {
      setTool(toolPersist)
    }
  }, [toolPersist])

  useEffect(() => {
    if (heightPersist !== height) {
      setHeight(heightPersist)
    }
  }, [heightPersist])

  const createEmbedLinkClicked = async () => {
    try {
      const data = {
        embedPage: tool === "d6" ? "dice" : tool,
        embedTool: tool,
      }
      const result = await dispatch(createEmbedLink(data))
      if (result.error) {
        setErrorMsg(result.error)
        return
      }
      const pathname = "/" + data.embedPage
      let fullPath = `https://flipsimu.com/emb` + pathname
      if (result.path !== "original") {
        fullPath = fullPath + `?id=${result.path}`
      }
      setErrorMsg(null)
      setEmbedUrl(fullPath)
      setIFrameCode(
        `<iframe src="${fullPath}" width="100%" height="${height}px" scrolling="no" frameborder="0"></iframe>`
      )
      setGetCodeClicked(4)
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    if (getCodeClicked === 2 || getCodeClicked === 3) {
      createEmbedLinkClicked()
    } else if (getCodeClicked === 0) {
      setEmbedUrl("")
      setIFrameCode("")
    }
  }, [getCodeClicked])

  return (
    <>
      <Box flexDirection="row" justifyContent="space-between">
        <ContentHeading>iFrame code for Website</ContentHeading>
        <CopyButton
          copyText={copyText}
          text={iFrameCode}
          setCopyText={value => setCopyText(value)}
        />
      </Box>
      <InstructionBox>
        {errorMsg ? (
          <Text color="reject" size="1.4rem">
            {errorMsg}
          </Text>
        ) : (
          <Content>{iFrameCode}</Content>
        )}
      </InstructionBox>

      <Box flexDirection="row" justifyContent="space-between">
        <ContentHeading>Code for Power Point</ContentHeading>
        <CopyButton
          copyText={copyText}
          text={embedUrl}
          setCopyText={value => setCopyText(value)}
        />
      </Box>
      <InstructionBox>
        {errorMsg ? (
          <Text color="reject" size="1.4rem">
            {errorMsg}
          </Text>
        ) : (
          <Content>{embedUrl}</Content>
        )}
      </InstructionBox>
    </>
  )
}

export default EmbedInstruction
