import React from "react"
import { Box, Input } from "../../commonElements"

const TextList = props => {
    let count = 0
    if ((props.type === "contents") && (props.tool === "d4" || props.tool === "d6" || props.tool === "d8" || props.tool === "d10" || props.tool === "d12" || props.tool === "d20")) {
        count = props.tool.replace("d", "")
    } else {
        count = 1   // one textbox
    }

    const array = []
    for (let i = 0; i < count; i++) {
        array.push(i)
    }

    const getValue = (index, option) => {
        let value = ""
        if (props.type === "texts") {
            value = props.checkedValue[0][option.value.split(",")[1]]
        } else if (props.type === "contents") {
            value = props.checkedValue[0].texts[index]
        } else {
            value = props.checkedValue
        }
        return value
    }

    const getReturnValue = (index, option, value) => {
        let returnValue = value
        if (props.type === "texts") {
            returnValue = option.value + "," + returnValue
        } else if (props.type === "contents") {
            returnValue = option.value + "," + returnValue + "," + index
        }
        return returnValue
    }

    return (
        <Box flexDirection="row" gap=".5rem" flexWrap="wrap">
            {array.map(index => {
                return (
                    <>
                        {props.options.map(option => {
                            return (
                                <Box mr="1rem" mb=".5rem">
                                    <Input
                                        value={getValue(index, option)}
                                        width="15rem"
                                        onChange={event => props.valueChanged(getReturnValue(index, option, event.target.value))}
                                        placeholder={`${option.placeholder + (count !== 1 ? " " + (index + 1) : "")}`}
                                    />
                                </Box>
                            )
                        })}
                    </>
                )
            })}
        </Box>
    )
}

export default TextList
