import React from "react"
import { Box, RadioButton } from "../../commonElements"
import styled from "styled-components"

const Container = styled(Box)`
  cursor: pointer;
`

const CircularBox = styled(Box)`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: ${props => props.bgColor};
  border: ${props => props.border};
`

const ColorPair = ({ colors, checked, setCurrentOption, border = "none" }) => {
  return (
    <Container
      onClick={setCurrentOption}
      alignItems="center"
      gap="0.2rem"
      mb="1rem"
    >
      <Box flexDirection="row" mb=".5rem">
        {
          Array.isArray(colors) ?
            (<>
              <CircularBox bgColor={colors[0]} border={border} />
              <CircularBox bgColor={colors[1]} border={border} />
            </>)
            :
            <CircularBox bgColor={colors} border={border} />
        }
      </Box>
      <RadioButton checked={checked} />
    </Container>
  )
}

export default ColorPair
