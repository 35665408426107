import React from "react"
import { Box } from "../../commonElements"
import ColorPair from "../colorSetting/colorPair"

const ColorList = props => {
    const isChecked = (pair) => {
        if (Array.isArray(props.checkedValue)) {
            return JSON.stringify(props.checkedValue) === JSON.stringify(pair)
        } else {
            return props.checkedValue === pair
        }
    }

    return (
        <Box flexDirection="row" gap="1rem" flexWrap="wrap">
            {props.options.map((pair, index) => {
                return (
                    <ColorPair
                        key={`colorPair${index}`}
                        colors={pair}
                        checked={isChecked(pair)}
                        setCurrentOption={() => props.valueChanged(pair)}
                        index={index}
                        border={props.type === "backgroundColor" ? "1px solid #000000" : "none"}
                    />
                )
            })}
        </Box>
    )
}

export default ColorList
