import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  GlassBox,
  Box,
  Text,
} from "../commonElements"
import styled from "styled-components"
import OptionSettings from "../toolSettings/embedSetting/optionSettings"
import {
  updateEmbedTool,
  updateEmbedBackgroundColor,
  updateEmbedColors,
  updateEmbedSound,
  updateEmbedHeight,
  updateEmbedQuantity,
  updateEmbedContentMode,
  updateEmbedContent,
  updateEmbedTexts,
  setEmbedTransformDice,
  setEmbedTransformCoin,
  updateEmbedDirection,
} from "../../redux/embedTool/actions"

const CustomizeBox = styled(GlassBox)`
  padding: 1rem;
  box-shadow: 0px 3px 10px #00000033;
`

const Container = styled.div`
  color: #3A3A3A;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 5fr 2fr 2fr;
  gap: 2.5rem 2rem;
  grid-template-areas:
    "Tools Settings Mute"
    "Content BackgroundColor BackgroundColor"
    "Colors Direction Blank";
  @media ${props => props.theme.device.tablet} {
    gap: 2rem 0rem;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      "Tools Settings"
      "Content Mute"
      "Content BackgroundColor"
      "Colors Direction";
  }
  @media ${props => props.theme.device.mobile} {
    grid-template-columns: auto;
    grid-template-areas:
      "Tools"
      "Settings"
      "Content"
      "Mute"
      "BackgroundColor"
      "Colors"
      "Direction";
  }
`

const ToolsBox = styled(Box)`
  grid-area: Tools;
  grid-auto-flow: row;
`

const SettingsBox = styled(Box)`
  grid-area: Settings;
  grid-auto-flow: row;
`

const MuteBox = styled(Box)`
  grid-area: Mute;
  grid-auto-flow: row;
`

const ContentBox = styled(Box)`
  grid-area: Content;
  grid-auto-flow: row;
`

const BackgroundColorBox = styled(Box)`
  grid-area: BackgroundColor;
  grid-auto-flow: row;
`

const ColorsBox = styled(Box)`
  grid-area: Colors;
  grid-auto-flow: row;
`

const DirectionBox = styled(Box)`
grid-area: Direction;
grid-auto-flow: row;
`

const BlankBox = styled(Box)`
  grid-area: Blank;
  grid-auto-flow: row;
  @media ${props => props.theme.device.mobile} {
    display: none;
  }
`

const HeadingText = styled(Text)`
  font-weight: 700;
`

const EmbedCustomize = ({ getCodeClicked, setGetCodeClicked }) => {
  const {
    tool: toolPersist,
    backgroundColor: backgroundColorPersist,
    colors: colorsPersist,
    direction: directionPersist,
  } = useSelector(state => state.embed)

  const soundOnPersist = useSelector(state => state.embed.sound.on)
  const quantityPersist = useSelector(state => state.embed[toolPersist].quantity)
  const contentModePersist = useSelector(state => state.embed[toolPersist].contentMode)
  const contentsPersist = useSelector(state => state.embed[toolPersist]?.contents)
  const textsPersist = useSelector(state => state.embed[toolPersist]?.texts)

  const [tool, setTool] = useState(null)
  const [backgroundColor, setBackgroundColor] = useState("#3A3A3A")
  const [colors, setColors] = useState(["#59569D", "#F25292"])
  const [soundOn, setSoundOn] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const [contentMode, setContentMode] = useState(null)
  const [contents, setContents] = useState([])
  const [texts, setTexts] = useState([])
  const [direction, setDirection] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    if (toolPersist !== tool) {
      setTool(toolPersist)
    }
  }, [toolPersist])

  useEffect(() => {
    if (backgroundColorPersist !== backgroundColor) {
      setBackgroundColor(backgroundColorPersist)
    }
  }, [backgroundColorPersist])

  useEffect(() => {
    if (colorsPersist !== colors) {
      setColors(colorsPersist)
    }
  }, [colorsPersist])

  useEffect(() => {
    if (soundOnPersist !== soundOn) {
      setSoundOn(soundOnPersist)
    }
  }, [soundOnPersist])

  useEffect(() => {
    if (quantityPersist !== quantity) {
      setQuantity(quantityPersist)
    }
  }, [quantityPersist])

  useEffect(() => {
    if (contentModePersist !== contentMode) {
      setContentMode(contentModePersist)
    }
  }, [contentModePersist])

  useEffect(() => {
    if (contentsPersist !== contents) {
      setContents(contentsPersist)
    }
  }, [contentsPersist])

  useEffect(() => {
    if (textsPersist !== texts) {
      setTexts(textsPersist)
    }
  }, [textsPersist])

  useEffect(() => {
    if (directionPersist !== direction) {
      setDirection(directionPersist)
    }
  }, [directionPersist])

  const getContentMode = () => {
    let mode = ""
    if (contentMode) {
      mode = contentMode[0]
      if (mode === "SHAPES") {
        mode = mode + "," + contents[0].shapes
      } else if (mode === "TEXTS") {
        mode = mode + ",texts"
      }
    }
    return mode
  }

  const handleValueChanged = (type, value) => {
    switch (type) {
      case "tool":
        dispatch(updateEmbedTool(value))
        break

      case "backgroundColor":
        dispatch(updateEmbedBackgroundColor(value))
        break

      case "colors":
        dispatch(updateEmbedColors(value))
        break

      case "sound":
        dispatch(updateEmbedSound(value))
        break

      case "direction":
        dispatch(updateEmbedDirection(value))
        break

      case "quantity":
        dispatch(updateEmbedQuantity(value, tool))
        if (tool === "d4" || tool === "d6" || tool === "d8" || tool === "d10" || tool === "d12" || tool === "d20") {
          dispatch(setEmbedTransformDice("contentMode", tool))
          dispatch(setEmbedTransformDice("contents", tool))
        } else if (tool === "coin") {
          dispatch(setEmbedTransformCoin("texts", tool))
        }
        const iframeHeight = value === 1 ? 430 : value === 2 ? 570 : 760
        dispatch(updateEmbedHeight(iframeHeight))
        break

      case "contentMode":
        const mode = value.split(",")[0]
        const tempMode = [...contentMode].map(() => {
          return mode
        })
        dispatch(updateEmbedContentMode(tempMode, tool))
        if (tool === "d4" || tool === "d6" || tool === "d8" || tool === "d10" || tool === "d12" || tool === "d20") {
          if (mode === "SHAPES") {
            const shapeMode = value.split(",")[1]
            const tempContents = [...contents].map(() => {
              return { shapes: shapeMode }
            })
            dispatch(updateEmbedContent(tempContents, tool))
          } else {
            dispatch(setEmbedTransformDice("contents", tool))
          }
        }
        break

      case "contents":
        const arrContent = value.split(",")
        const contentType = arrContent[1], contentInput = arrContent[2], contentIndex = parseInt(arrContent[3])
        const tempContents = [...contents].map(contentObj => {
          if (contentObj[contentType] !== undefined) {
            const temp = [...contentObj[contentType]].map((content, id) => {
              if (id === contentIndex) {
                return contentInput
              }
              return content
            })
            return { ...contentObj, [contentType]: temp }
          } else {
            return contentObj
          }
        })
        dispatch(updateEmbedContent(tempContents, tool))
        break

      case "texts":
        const arrText = value.split(",")
        const textType = arrText[1], textInput = arrText[2]
        const temp = [...texts].map(obj => {
          return { ...obj, [textType]: textInput }
        })
        dispatch(updateEmbedTexts(temp, tool))
        break
    }

    if ((type === "tool") || (getCodeClicked === 0)) {
      setGetCodeClicked(0)
    } else {
      setGetCodeClicked(1)
    }
  }

  return (
    <CustomizeBox>
      <Container>
        <ToolsBox>
          <HeadingText>Tools:</HeadingText>
          <OptionSettings tool={tool} type="coin" checkedValue={tool} valueChanged={value => handleValueChanged("tool", value)} />
          <OptionSettings tool={tool} type="dice" title="Dice Roller:" checkedValue={tool} valueChanged={value => handleValueChanged("tool", value)} />
        </ToolsBox>
        <SettingsBox>
          <HeadingText>Settings:</HeadingText>
          <OptionSettings tool={tool} type="quantity" title="Quantity:" checkedValue={quantity} valueChanged={value => handleValueChanged("quantity", value)} />
        </SettingsBox>
        <MuteBox>
          <HeadingText>Mute:</HeadingText>
          <OptionSettings tool={tool} type="sound" checkedValue={soundOn} valueChanged={value => handleValueChanged("sound", value)} />
        </MuteBox>
        <ContentBox>
          <HeadingText>Content:</HeadingText>
          <OptionSettings tool={tool} type="contentMode" checkedValue={getContentMode()} valueChanged={value => handleValueChanged("contentMode", value)} />
          {contentMode && contentMode[0] === "TEXTS" && (<>
            {tool === "coin" ? <OptionSettings tool={tool} type="texts" checkedValue={texts} valueChanged={value => handleValueChanged("texts", value)} />
              : <OptionSettings tool={tool} type="contents" checkedValue={contents} valueChanged={value => handleValueChanged("contents", value)} />
            }
          </>)}
        </ContentBox>
        <BackgroundColorBox>
          <HeadingText>Background Color:</HeadingText>
          <OptionSettings tool={tool} type="backgroundColor" checkedValue={backgroundColor} valueChanged={value => handleValueChanged("backgroundColor", value)} />
        </BackgroundColorBox>
        <ColorsBox>
          <HeadingText>Colors:</HeadingText>
          <OptionSettings tool={tool} type="colors" checkedValue={colors} valueChanged={value => handleValueChanged("colors", value)} />
        </ColorsBox>
        <DirectionBox>
          <HeadingText>Direction:</HeadingText>
          <OptionSettings tool={tool} type="direction" checkedValue={direction} valueChanged={value => handleValueChanged("direction", value)} />
        </DirectionBox>
        <BlankBox />
      </Container>
    </CustomizeBox>
  )
}

export default EmbedCustomize