import React from "react"
import { Box, RadioButton, Text } from "../../commonElements"
import styled from "styled-components"

const Container = styled(Box)`
  cursor: pointer;
`

const RadioButtonList = props => {
  return (
    <Box flexDirection="row" gap=".5rem" flexWrap="wrap">
      {props.options.map((option, index) => {
        return (
          <Container
            key={`radio-button-${index}`}
            onClick={() => props.valueChanged(option.value)}
            alignItems="center"
            flexDirection="row"
            gap=".5rem"
          >
            <Text>{option.label}</Text>
            <RadioButton checked={props.checkedValue === option.value} />
          </Container>
        )
      })}
    </Box>
  )
}

export default RadioButtonList
