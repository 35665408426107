import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Box, Heading, Image } from "../components/commonElements"
import Layout from "../layout/GeneralLayout"
import styled from "styled-components"
import Footer from "../components/footer"
import SEO from "../components/seo"
import EmbedDiceImage from "../images/embed/embed-dice.jpg"
import EmbedCoinImage from "../images/embed/embed-coin.jpg"
import EmbedCustomize from "../components/embedTools/embedCustomize"
import EmbedPreview from "../components/embedTools/embedPreview"
import EmbedInstruction from "../components/embedTools/embedInstruction"
import EmbedModal from "../components/modal/EmbedModal"

const Container = styled(Box)`
  color: white;
  align-items: center;
  max-height: 66rem;
  max-width: ${props => `calc(${props.theme.containerSize} - 2rem)`};
  width: calc(100% - 2rem);
  padding: 5rem 2rem 7rem;
`

const InnerContainer = styled.div`
  max-width: 100rem;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 3fr 2fr;
  grid-template-areas:
    "HeadingTitle HeadingCoinImage"
    "HeadingDiceImage HeadingCoinImage";
`

const HeadingTitle = styled(Box)`
  grid-area: HeadingTitle;
  grid-auto-flow: row;
  padding-bottom: 3rem;
  padding-right: 4rem;
  & > h1 {
    line-height: 3.2rem;
  }
  @media ${props => props.theme.device.tablet} {
    padding-bottom: 1rem;
    & > h1 {
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }
  @media ${props => props.theme.device.mobile} {
    padding-bottom: 0rem;
    & > h1 {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
`

const HeadingCoinImage = styled(Box)`
  grid-area: HeadingCoinImage;
  grid-auto-flow: row;
  & > img {
    margin-left: -3rem;
    @media ${props => props.theme.device.mobile} {
      margin-left: -1rem;
    }
  }
`

const HeadingDiceImage = styled(Box)`
  grid-area: HeadingDiceImage;
  grid-auto-flow: row;
  padding-bottom: 10rem;
  @media ${props => props.theme.device.tablet} {
    padding-bottom: 0rem;
  }
  & > img {
    z-index: -1;
  }
`
const Container2 = styled(Box)`
  background-color: white;
  align-items: center;
  width: 100%;
  padding: 5rem 1rem 10rem;
  margin-bottom: 0;
`

const InnerContainer2 = styled.div`
  max-width: 120rem;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto;
  gap: 5rem 0rem;
  -webkit-tap-highlight-color: transparent;
`

const EmbedTutorial = () => {
  const heightPersist = useSelector(state => state.embed.height)
  const [height, setHeight] = useState(0)
  const [getCodeClicked, setGetCodeClicked] = useState(0)
  const embedModalOpened = useSelector(state => state.modal.embedModalOpened)
  // Steps: 0 -> 2 -> 4 -> 1 -> 3 -> 4
  // 0- show "get embed code" button & hide embed code
  // 1- show "update embed code" button (any settings changes made)
  // 2- show "get embed code" disabled button with loader (creating new embed link)
  // 3- show "update embed code" disabled button with loader (update existing embed link)
  // 4- show "update embed code" disabled button (done created embed link)

  useEffect(() => {
    if (heightPersist !== height) {
      setHeight(heightPersist)
    }
  }, [heightPersist])

  return (
    <>
      {/* <Box flex="1" mt="1rem" alignItems="center" gap="1rem"> */}
      <Layout>
        <Container>
          {embedModalOpened && <EmbedModal />}
          <InnerContainer>
            <HeadingTitle>
              <Heading level={1} textAlign="center">
                Embed a custom
                <br />
                FlipSimu Coin & Dice
                <br />
                on your blog, website, or power point
              </Heading>
            </HeadingTitle>
            <HeadingCoinImage>
              <Image
                src={EmbedCoinImage}
                alt="Embed Coin"
                width="100%"
                style={{
                  boxShadow: "0px 3px 10px #ffffff33",
                }}
              />
            </HeadingCoinImage>
            <HeadingDiceImage>
              <Image
                src={EmbedDiceImage}
                alt="Embed Dice"
                width="100%"
                style={{
                  alignSelf: "self-end",
                  boxShadow: "0px 3px 10px #00000033",
                }}
              />
            </HeadingDiceImage>
          </InnerContainer>
        </Container>
      </Layout>
      <Container2>
        <InnerContainer2>
          <Box>
            <Heading level={4}>Step 1 Customize:</Heading>
            <EmbedCustomize
              getCodeClicked={getCodeClicked}
              setGetCodeClicked={value => setGetCodeClicked(value)}
            />
          </Box>
          <Box>
            <Heading level={4}>Step 2 Preview:</Heading>
            <EmbedPreview
              getCodeClicked={getCodeClicked}
              setGetCodeClicked={value => setGetCodeClicked(value)}
            />
          </Box>
          <Box>
            <Heading level={4}>Embed Instructions:</Heading>
            <EmbedInstruction
              getCodeClicked={getCodeClicked}
              setGetCodeClicked={value => setGetCodeClicked(value)}
            />
          </Box>
        </InnerContainer2>
      </Container2>
      <Footer />
      {/* </Box> */}
    </>
  )
}

export default EmbedTutorial

export const Head = () => (
  <SEO
    titleP="Embed Tutorial | FlipSimu"
    descriptionP="This is the embed tutorial page of FlipSimu"
    pathname="/embed-tutorial/"
  />
)
