import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { GlassBox, Button, Box, Text, Loader } from "../commonElements"
import styled from "styled-components"
import CirclePlaceholder from "../../components/embedTools/circlePlaceholder"
import Header from "../../components/embedTools/header"
import Coin from "../../components/embedTools/coinSimu"
import Dice from "../../components/embedTools/diceRoller"

const PreviewBox = styled(Box)`
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #3a3a3a;
`

const ContentButton = styled(Button)`
  background-color: ${props => props.backgroundColor};
  line-height: ${props => props.lineHeight};
  font-size: ${props => props.fontSize};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  margin-top: ${props => props.mt};
  cursor: ${props => props.cursor};
  opacity: ${props => props.opacity};
`

const EmbedBox = styled(Box)`
  background-color: white;
  align-self: center;
  width: 100%;
  z-index: 0;
  display: ${props => (!props.isEmbed ? "none" : "flex")};
  @media (max-width: 339px) {
    display: ${props => (props.isEmbed ? "none" : "flex")};
  }
`

const ButtonLoader = styled(Loader)`
  font-size: 5px;
  color: white;
  margin-left: 2.5rem;
`

const EmbedPreview = ({ getCodeClicked, setGetCodeClicked }) => {
  const { tool: toolPersist, height: heightPersist, direction: directionPersist } = useSelector(
    state => state.embed
  )
  const quantityPersist = useSelector(
    state => state.embed[toolPersist].quantity
  )
  const [tool, setTool] = useState(null)
  const [height, setHeight] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [direction, setDirection] = useState(0)

  useEffect(() => {
    if (toolPersist !== tool) {
      setTool(toolPersist)
    }
  }, [toolPersist])

  useEffect(() => {
    if (heightPersist !== height) {
      setHeight(heightPersist)
    }
  }, [heightPersist])

  useEffect(() => {
    if (quantityPersist !== quantity) {
      setQuantity(quantityPersist)
    }
  }, [quantityPersist])

  useEffect(() => {
    if (directionPersist !== direction) {
      setDirection(directionPersist)
    }
  }, [directionPersist])

  let embedPage = ""
  switch (tool) {
    case "coin":
      embedPage = <Coin />
      break
    case "d4":
    case "d6":
    case "d8":
    case "d10":
    case "d12":
    case "d20":
      embedPage = <Dice diceType={tool} />
      break
  }

  const handleButtonClicked = () => {
    if (getCodeClicked === 0) {
      setGetCodeClicked(2)
    } else if (getCodeClicked === 1) {
      setGetCodeClicked(3)
    }
  }

  const buttonText = () => {
    let buttonText = ""
    if (getCodeClicked === 0 || getCodeClicked === 2) {
      buttonText = "Get Embed Code"
    } else if (
      getCodeClicked === 1 ||
      getCodeClicked === 3 ||
      getCodeClicked === 4
    ) {
      buttonText = "Update Embed Code"
    }
    return buttonText
  }

  return (
    <>
      <GlassBox
        padding="2rem 1rem"
        alignItems="center"
        style={{
          backgroundColor: "#3A3A3A",
          border: "none",
        }}
      >
        <PreviewBox width={direction === 0 ? "300px" : "100%"}>
          <EmbedBox isEmbed={false}>
            <Header />
            <CirclePlaceholder />
          </EmbedBox>
          <EmbedBox isEmbed={true}>
            <Header />
            {embedPage}
          </EmbedBox>
        </PreviewBox>
        <ContentButton
          onClick={() => handleButtonClicked()}
          backgroundColor="#F15191"
          mt="1.5rem"
          opacity={getCodeClicked === 0 || getCodeClicked === 1 ? "1" : "0.5"}
          cursor={
            getCodeClicked === 0 || getCodeClicked === 1 ? "pointer" : "auto"
          }
        >
          {
            <Box flexDirection="row" alignItems="center">
              {buttonText()}
              {(getCodeClicked === 2 || getCodeClicked === 3) && (
                <ButtonLoader />
              )}
            </Box>
          }
        </ContentButton>
      </GlassBox>
      <Text fontSize="1.4rem" mt=".5rem">
        *Click "Get Embed Code" button for generating new embed code and "Update
        Embed Code" button for applying latest settings to the embed code.
      </Text>
    </>
  )
}

export default EmbedPreview
