import React from "react"
import { Box, Text } from "../../commonElements"
import styled from "styled-components"
import RadioButtonList from "./radioButtonList"
import QuantityList from "./quantityList"
import ColorList from "./colorList"
import TextList from "./textList"

const TitleText = styled(Text)`
  margin-right: 1rem;
`

const coinToolsItems = [{ label: "Coin Flipper", value: "coin" }]

const diceToolsItems = [
  { label: "D4", value: "d4" },
  { label: "D6", value: "d6" },
  { label: "D8", value: "d8" },
  { label: "D10", value: "d10" },
  { label: "D12", value: "d12" },
  { label: "D20", value: "d20" },
]

const quantityItems = [1, 2, 3]

const soundItems = [
  { label: "True", value: true },
  { label: "False", value: false },
]

const contentModeItems = [
  // value: contentMode,contents
  { tool: ["coin"], label: "Texts", value: "TEXTS,texts" },
  {
    tool: ["d4", "d6", "d8", "d10", "d12", "d20"],
    label: "Texts",
    value: "TEXTS,texts",
  },
  { tool: ["d6"], label: "Dots", value: "SHAPES,dots" },
  {
    tool: ["d4", "d6", "d8", "d10", "d12", "d20"],
    label: "Numbers",
    value: "SHAPES,numbers",
  },
]

const textsItems = [
  { tool: ["coin"], placeholder: "Heads", value: "texts,heads" },
  { tool: ["coin"], placeholder: "Tails", value: "texts,tails" },
  {
    tool: ["d4", "d6", "d8", "d10", "d12", "d20"],
    placeholder: "Face",
    value: "contents,texts",
  },
]

const backgroundColorItems = ["#282728", "#FFFFFF"]

const colorsItems = [
  ["#59569D", "#F25292"],
  ["#07689f", "#ff7e67"],
  ["#2c786c", "#f8b400"],
  ["#303841", "#d4a5a5"],
  ["#574b90", "#cfb495"],
  ["#005792", "#f7be16"],
  ["#086972", "#ff6f3c"],
  ["#252a34", "#ff2e63"],
]

const directionItems = [
  { label: "Column", value: 0 },
  { label: "Row", value: 1 },
]

// const widthItems = [
//     { placeholder: "Min. 300" },
// ]

const Container = styled(Box)`
  flex-direction: row;
  margin-bottom: 0.5rem;
  align-items: center;
  @media ${props => props.theme.device.mobile} {
    align-items: flex-start;
  }
`

const OptionSettings = props => {
  let checkedValue = props.checkedValue
  let optionItems = null
  let option = null

  switch (props.type) {
    case "coin":
      optionItems = coinToolsItems
      option = 1
      break
    case "dice":
      optionItems = diceToolsItems
      option = 1
      break
    case "quantity":
      optionItems = quantityItems
      option = 2
      break
    case "sound":
      optionItems = soundItems
      option = 1
      break
    case "contentMode":
      optionItems = contentModeItems.filter(item =>
        item.tool.includes(props.tool)
      )
      option = 1
      break
    case "backgroundColor":
      optionItems = backgroundColorItems
      option = 3
      break
    case "colors":
      optionItems = colorsItems
      option = 3
      break
    case "texts":
    case "contents":
      optionItems = textsItems.filter(item => item.tool.includes(props.tool))
      option = 4
      break
    case "direction":
      optionItems = directionItems
      option = 1
      break
    // case "width":
    //     optionItems = widthItems
    //     option = 5
    //     break
  }

  return (
    <Container>
      {props.title && props.title !== "" && (
        <TitleText>{props.title}</TitleText>
      )}
      {option === 1 && (
        <RadioButtonList
          tool={props.tool}
          type={props.type}
          options={optionItems}
          checkedValue={checkedValue}
          valueChanged={props.valueChanged}
        />
      )}
      {option === 2 && (
        <QuantityList
          tool={props.tool}
          options={optionItems}
          checkedValue={checkedValue}
          valueChanged={props.valueChanged}
        />
      )}
      {option === 3 && (
        <ColorList
          type={props.type}
          options={optionItems}
          checkedValue={checkedValue}
          valueChanged={props.valueChanged}
        />
      )}
      {option === 4 && (
        <TextList
          tool={props.tool}
          type={props.type}
          options={optionItems}
          checkedValue={checkedValue}
          valueChanged={props.valueChanged}
        />
      )}
      {/* {option === 5 && <TextList tool={props.tool} type={props.type} options={optionItems} checkedValue={checkedValue} valueChanged={props.valueChanged} />} */}
    </Container>
  )
}

export default OptionSettings
