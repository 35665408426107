import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Box, Heading, Text, Tooltip, Anchor } from "../commonElements"
import styled from "styled-components"
import { getLoadEmbedList } from "../../redux/embedLink/actions"
import MoreButton from "./moreButton"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useToastContext } from "../contextHook/toast"
import { isMobile } from "react-device-detect"

const LinkRow = styled(Box)`
  &:hover {
    border-radius: 0.5rem;
    background-color: #f2f2f2;
  }
`

const Modal = styled(Box)`
  background-color: white;
  height: 100%;
  color: ${props => props.theme.colors.text};
  @media screen and (max-width: 600px) {
    display: block;
  }
`

const ModalContainer = styled(Box)`
  flex-flow: column;
  overflow-y: auto;
  height: calc(100% - 5rem);
  max-width: 55rem;
  &::-webkit-scrollbar {
    width: 3px !important;
  }
  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollbar} !important;
    border-radius: 5px;
  }
`

const RowSeparator = styled.hr`
  background-color: transparent;
  border-top: 0.5px solid rgb(205, 205, 205);
  margin: 0.5rem 0 1rem;
`

const getFullPath = (page, code) => {
  return `https://flipsimu.com/emb/` + page + `?id=` + code
}

const EmbedLinkList = () => {
  const authenticated = useSelector(state => state.auth.authenticated)
  const [loading, setLoading] = useState(false)
  const [linksList, setLinksList] = useState([])

  useEffect(() => {
    if (authenticated) {
      setLoading(true)
      getLoadEmbedList()
        .then(list => {
          setLinksList(list)
          setLoading(false)
        })
        .catch(error => {
          console.log("error", error.message)
          setLoading(false)
        })
    }
  }, [])

  const { setToast } = useToastContext()
  const [copyText, setCopyText] = useState("Click to Copy")

  let previousDate

  return (
    <Modal
      borderRadius="1rem"
      alignItems="center"
      width="100%"
      alignSelf="stretch"
      justifyContent="flex-start"
      topP="0px"
      bottomP="0px"
    >
      <Box
        alignItems="center"
        justifyContent="center"
        positionBox="relative"
        mb="1.5rem"
      >
        <Heading mb=".5rem">
          EMBED LINKS{" "}
          {!loading && (
            <Text
              fontSize="1.6rem"
              marginLeft="1rem"
              style={{
                position: "absolute",
                border: "1px solid #3D3D3D",
                padding: ".3rem",
              }}
            >
              {linksList.length}
            </Text>
          )}
        </Heading>
        <Text
          fontSize="1.2rem"
          style={{ lineHeight: "1.4", maxWidth: "52rem" }}
        >
          To embed in PowerPoint, use the embed link directly; for website, use
          the iframe code:
          <br />
          <Box backgroundColor="#f2f2f2" padding="0 .5rem" borderRadius=".2rem">
            {`<iframe src="[link]" width="100%" height="430px" scrolling="no" frameborder="0"></iframe>`}
          </Box>
        </Text>
      </Box>
      <ModalContainer
        alignItems="center"
        positionBox="relative"
        width="100%"
        margin="0 0.5rem 0 1rem"
        pt="1rem"
        pr="1rem"
        overflow="auto"
      >
        {authenticated ? (
          <Box width="100%" maxWidth="59rem" mb="10rem">
            {linksList.map((item, index) => {
              let isShowDate = false
              if (index === 0) {
                isShowDate = true
                previousDate = item.createdDate
              } else if (previousDate != item.createdDate) {
                isShowDate = true
                previousDate = item.createdDate
              }
              return (
                <>
                  {isShowDate && (
                    <>
                      {index > 0 && <RowSeparator />}
                      <Text fontWeight="600">{item.createdDate}</Text>
                    </>
                  )}
                  <LinkRow
                    flexDirection="row"
                    justifyContent="space-between"
                    padding="0.3rem 0"
                    minHeight="3rem"
                  >
                    <Box flexDirection="row" gap="2rem" mr="2rem">
                      <Text marginLeft="1.5rem" style={{ width: "7rem" }}>
                        {item.createdTime}
                      </Text>
                      <Tooltip content={copyText}>
                        <CopyToClipboard
                          text={getFullPath(item.page, item.path)}
                          onCopy={() => {
                            setCopyText("Copied")
                            if (isMobile) {
                              setToast("Copied")
                            }
                          }}
                        >
                          <Text
                            style={{
                              userSelect: "text",
                              wordBreak: "break-word",
                            }}
                            onMouseLeave={() => {
                              setCopyText("Click to Copy")
                            }}
                          >
                            {getFullPath(item.page, item.path)}
                          </Text>
                        </CopyToClipboard>
                      </Tooltip>
                    </Box>
                    <MoreButton
                      embedPage={item.page}
                      fullPath={getFullPath(item.page, item.path)}
                      path={item.path}
                      linksList={linksList}
                      removeLink={() => {
                        const updatedLinksList = linksList.filter(
                          link => link.path !== item.path
                        )
                        setLinksList(updatedLinksList)
                      }}
                    />
                  </LinkRow>
                </>
              )
            })}
            <Box minHeight="4rem" />
          </Box>
        ) : (
          <Text textAlign="center" style={{ paddingTop: "10rem" }}>
            To view the embed links list, you need to
            <br />
            <Anchor
              href="/signup/"
              style={{
                textDecoration: `underline`,
              }}
              alt="signup"
            >
              sign up
            </Anchor>{" "}
            or{" "}
            <Anchor
              href="/login/"
              style={{
                textDecoration: `underline`,
              }}
              alt="login"
            >
              login
            </Anchor>
          </Text>
        )}
      </ModalContainer>
    </Modal>
  )
}

export default EmbedLinkList
