import { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

export const useAsync = (asyncFunction, auto = false) => {
  const [status, setStatus] = useState('idle')
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (...args) => {
      setStatus('pending')
      setValue(null)
      setError(null)
      return asyncFunction(...args)
        .then(response => {
          setValue(response)
          setStatus('success')
          return response
        })
        .catch(error => {
          console.log('error', error)
          setError(error)
          setStatus('error')
          throw error
        })
    },
    [asyncFunction],
  )
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (auto) {
      execute()
    }
  }, [execute, auto])
  return { execute, status, loading: status === 'pending', value, error }
}

export const useAsyncDispatch = (asyncFunction, auto = false) => {
  const [status, setStatus] = useState('idle')
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (...args) => {
      setStatus('pending')
      setValue(null)
      setError(null)
      return dispatch(asyncFunction(...args))
        .then(response => {
          setValue(response)
          setStatus('success')
          return response
        })
        .catch(error => {
          console.log('error', error)
          setError(error)
          setStatus('error')
          throw error
        })
    },
    [asyncFunction],
  )
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (auto) {
      execute()
    }
  }, [execute, auto])
  return { execute, status, loading: status === 'pending', value, error }
}
