import React from "react"
import { Box, IconButton } from "../commonElements"
import { Times } from "@styled-icons/fa-solid"
import { useDispatch } from "react-redux"
import { openEmbedModal } from "../../redux/modal/actions"
import Backdrop from "./Backdrop"
import EmbedLinkList from "../embedTools/embedLinkList"

const EmbedModal = () => {
  const dispatch = useDispatch()

  return (
    <Backdrop>
      <Box
        maxWidth="60rem"
        width="95%"
        height="50rem"
        backgroundColor="white"
        alignItems="center"
        borderRadius="1rem"
        positionBox="relative"
        padding="3rem 1rem"
      >
        <Box positionBox="absolute" rightP=".7rem" topP=".7rem">
          <IconButton
            icon={<Times size={21} />}
            onClick={() => {
              dispatch(openEmbedModal(false))
            }}
          />
        </Box>
        <EmbedLinkList />
      </Box>
    </Backdrop>
  )
}
export default EmbedModal
